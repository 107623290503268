<template>
  <div class="history">
    <van-nav-bar title="歷史記錄" left-arrow :z-index='111' class="qjc-nav-bar" @click-left="$router.push('/myLink')" />
    <div class="history-content" @scroll="scrollHandler">
      <div class="history-line" v-for="item in historyList" :key="item.id" @click="toResult(item)">
        <div class="name" v-if="item.code === 'M22'">AI急重症預警
        </div>
        <div class="name" v-else-if="item.code === 'M20'">
          AI體檢(需要血液檢查指標)
        </div>
        <div class="name" v-else-if="item.code === 'FTE'">無創AI自測</div>
        <div class="time">{{ item.created_at }}</div>
      </div>
      <div class="no-history" v-if="historyList.length === 0">
        <img class="medin-icon" src="@/assets/images/history/medin-icon.png" alt="">
        <div class="tip">你暫時還沒有歷史評估記錄哦~</div>
        <div class="no-history-btn" @click="$router.push('/')">前往AI智慧健康自測</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { NavBar, Image, Grid, GridItem, Tabbar, TabbarItem, Button, ImagePreview, Popup, Toast } from 'vant'
Vue.use(NavBar)
  .use(Image)
  .use(Grid)
  .use(GridItem)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Button)
  .use(Toast)
  .use(ImagePreview)
  .use(Popup);

import { listMyLink } from '@/js/axios.js'
import { Base64 } from 'js-base64'


export default {
  name: 'userHistory',
  data () {
    return {
      navIcon: {
        active: 'user',
        home: {
          normal: require('@/assets/images/shouye1.png'),
          active: require('@/assets/images/shouye.png')
        },
        study: {
          normal: require('@/assets/images/study.png'),
          active: require('@/assets/images/study_active.png')
        },
        learn: {
          normal: require('@/assets/images/learn.png'),
          active: require('@/assets/images/learn-active.png')
        },
        user: {
          normal: require('@/assets/images/wode.png'),
          active: require('@/assets/images/wode2.png')
        }
      },// 底部導航欄icon
      pager: {
        page: 1,//當前頁，默認第0頁
        pagesize: 20,//每頁多少條數據 默認10
      },
      lists: [],//歷史列表數據
      loading: false,//是否處於加載狀態
      finished: false,//是否加載完成
      historyList: [],
      total: 0,
      isScrollLoadOpen: false
    }
  },
  computed: {

  },
  created () {
    this.getHistoryData()
  },
  mounted () {

  },
  beforeDestroy () {

  },
  methods: {
    async getHistoryData () {

      const result = await listMyLink(this.pager)
      if (result.code === 200) {

        const data = JSON.parse(Base64.decode(result.data))
        console.log("data", data);

        this.historyList = data.data
        this.total = Number(data.pager.count)
        // console.log("data", data);

        if (this.total > this.pager.pagesize) {
          this.isScrollLoadOpen = true
        } else {
          this.isScrollLoadOpen = false
        }
      }
    },
    scrollHandler (e) {
      const { scrollHeight, scrollTop, offsetHeight } = e.target
      // console.log(this.isScrollLoadOpen, "scrollTop", scrollTop, "scrollHeight", scrollHeight, "offsetHeight", offsetHeight);

      if (this.isScrollLoadOpen) {
        if (scrollTop + offsetHeight >= scrollHeight - 30) {
          this.isScrollLoadOpen = false
          this.pager.pagesize = this.pager.pagesize + 20
          this.getHistoryData()
        }
      }
    },
    toResult (item) {
      if (item.code == "M22") {
        this.$router.push(`/myLink/result/${item.id}`)

      } else if (item.code == "M20") {
        this.$router.push(`/myLink/result/bj/${item.id}`)

      } else if (item.code == 'FTE') {
        this.$router.push(`/myLink/result/${item.id}`)
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.history {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: .28rem;
  overflow: hidden;
  background-color: #6681FA;
  color: #fff;

  .qjc-nav-bar {
    flex: 0 0 auto;
  }

  .history-tabbar {
    flex: 0 0 auto;
    position: relative !important;
  }

  .history-content {
    position: relative;
    width: 100%;
    flex: 1;
    padding: .24rem;
    background-color: #00000014;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .no-history {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .medin-icon {
        width: 3.4rem;
      }

      .tip {
        margin: .32rem 0;
      }

      .no-history-btn {
        padding: .08rem .32rem;
        border-radius: .04rem;
        border: .02rem solid #fff;
      }
    }


    .history-line {
      background-color: #fff;
      margin-bottom: .24rem;
      padding: 0 .24rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: .12rem;
      color: #333;
      height: 54px;
      min-height: 50px;

      >div {
        white-space: nowrap;

      }

      .name {
        font-size: .32rem;
        overflow: hidden;
        margin-right: 12px;
      }

      .time {
        color: #999;
        font-size: .22rem;
      }
    }
  }
}
</style>